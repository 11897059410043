export const Colors = {
    error: '#FF2740',
    success: '#6AD638',
    darkBlue40: '#9A9FDD',
    darkBlue20: '#CCCFEE',
    darkBlue5: '#F2F3FA',
    darkBlue3: '#ECF9FF',
    lightBlue100: '#4FADFF',
    lightBlue80: '#72BDFF',
    lightBlue60: '#95CEFF',
    lightBlue40: '#B9DEFF',
    lightBlue20: '#DCEFFF',
    lightBlue5: '#F6FBFF',
    //benchmarking colors
    chartUp: '#00E399',
    chartDown: '#FF001A',
    navigationalSearch: '#0088FF',
    //channels colors
    basketValue: '#6709CB',
    darkYellow100: '#FFC003',
    petrol80: '#39B9C1',
    pink60: '#FA8EFC',
    decrease: '#A80505',
    darkGreen100: '#007C1B',
    red60: '#FF6B84',
    orange20: '#FFDFD1',
    pink80: '#F868FB',
    //social colors
    facebook: '#1877F2',
    instagram: '#EFD1E1',
    youtube: '#FF0000',
    reddit: '#F99059',
    linkedIn: '#3275B1',
    pinterest: '#AB0720',
    tiktok: '#000100',
    twitter: '#72C6FF',
};
