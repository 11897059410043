import '../styles/globals.css';
import { ThemeProvider as StyledProvider } from 'styled-components';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '@/utils/theme';
import { RecoilRoot } from 'recoil';
import './App.css';
export default function App({ Component, pageProps }) {
    return (<StyledProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <Component {...pageProps}/>
        </RecoilRoot>
      </ThemeProvider>
    </StyledProvider>);
}
